(function () {

    var HomePageAnalytics = flx.homePageAnalytics = {

        // Put home page analytics here as needed.

    };

    // Add event listeners here as needed.

})();
